<app-nav-bar></app-nav-bar>
<div class="app-navbar-pt"></div>

<main class="app-section app-min-height img" data-stellar-background-ratio="0.5">
	<div class="container app-form-container">
		<h2 class="text-center">Ops. Non ho trovato questa pagina</h2>

		<div class="mt-3 text-center">
			<a class="d-block" [routerLink]="'/'"> Torna alla Home</a>
		</div>
	</div>
</main>

<app-footer></app-footer>
